import {Dropzone} from 'dropzone';
const axios = require('axios');
const FormData = require('form-data');

import {largeNotification} from "./UIModules/largeNotification";
require("bootstrap")

$(document).on('ready turbolinks:load', function(){
    if($('.dropzone').length > 0){

        Dropzone.autoDiscover = false;
        let isUploading = false;

        new Dropzone("#mydropzone", {
            url: "/asset_files", // путь для создания AssetFile
            paramName: "asset_file[image]", // Имя параметра, ожидаемого сервером
            maxFiles: 100,
            maxFilesize: 100, // Максимальный размер файла (в мегабайтах)
            acceptedFiles: ".zip, .pptx, .xlsx, .png, .jpeg, .jpg, .pdf, .mp4, .tif", // Допустимые типы файлов
            addRemoveLinks: false,
            dictDefaultMessage: "Drop files here or click to upload.",
            dictFallbackMessage: "Your browser does not support drag&drop.",
            dictInvalidFileType: "Incorrect file format.",
            dictFileTooBig: "The file is big. Max size: {{maxFilesize}} Mb.",
            dictRemoveFile: "Remove",
            createImageThumbnails: false,
            headers: {
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content') // Передаём CSRF-токен
            },

            init: function() {
                this.on("addedfile", file => {
                    if (!isUploading) {
                        isUploading = true;
                        $('#wait-spinner').modal('show');
                    }
                });
                this.on("queuecomplete", file => {
                    setTimeout(()=> {
                        isUploading = false
                        $('#wait-spinner').modal('hide');
                    }, 2000)
                });
                this.on("success", function(file, response) {
                    this.removeFile(file);

                    // Добавление нового элемента в список после успешной загрузки
                    const cardviewList = document.querySelector(".cardview-list");

                    if(response && response.length > 0){
                        response.forEach((elem)=>{
                            const cardItem = document.createElement("div");
                            cardItem.classList.add("card-item");

                            cardItem.innerHTML = `
                                <button class="close-popup close" title="Close" aria-label="Close" onClick="removeAddedFile('/asset_files/${elem.asset_file_id}')"></button>
                                <div class="card-image">
                                    <img src="${elem.image_url}" alt="${elem.image_url}">
                                </div>
                                <div class="card-content">
                                    <div class="cart-title">
                                        <b>${elem.title || 'Untitled File'}</b>
                                    </div>
                                    <div class="card-types">
                                        <p>${elem.image_format}</p>
                                        <p>${elem.image_size}</p>
                                    </div>
                                </div>`;

                            cardviewList.appendChild(cardItem);
                        })
                    }

                    $('.files-counter b').text($('.cardview-container').find('.card-item').length);
                });

                this.on("error", function(file, error, xhr) {
                    console.log("Upload error:", error);
                });
            }
        });

        document.querySelector('.sent-for-approval').addEventListener('click', async () =>  {

            $('#wait-spinner').modal('show');

            const profileName = 'PRX - Waiting Room Uploads';
            // const profileName = 'ASD - eBooks'
            const regex = /\[[^\]]*\]/g;

            const allImages = document.querySelectorAll('.card-image img')
            const allTitles = document.querySelectorAll('.card-content b')
            const images = []
            const titles = []

            for (const file of allImages) images.push(file.src)
            for (const text of allTitles) titles.push(text.innerText)

            const filterValues = []
            const selectTitleElements = document.querySelectorAll('.select-title');
            selectTitleElements.forEach(selectTitle => {
                const parent = selectTitle.parentElement.parentElement;
                const filboxTitle = parent.querySelector('.filbox-title-default');
                const filboxTitleValue = filboxTitle.value.toLocaleLowerCase()
                const value = selectTitle.innerText.replace(regex, '').trim();

                if(value && value !== filboxTitle.value){
                    filterValues.push({[filboxTitleValue]: value})
                }
            });

            const promises = [];

            let metadataValues = filterValues.reduce((acc, obj) => ({ ...acc, ...obj }), {});

            for (let i = 0; i < document.querySelectorAll('.card-image img').length; i++) {
                const formData = new FormData();
                const response = await fetch(images[i]);
                const fileData = await response.blob();

                const fileName = titles[i];

                const metadata = {
                    'fields': {
                        "brand": metadataValues['brand'] ? [metadataValues['brand']] : [],
                        "variants": metadataValues['product variant'] ? [metadataValues['product variant']] : [],
                        "digitalMedia": metadataValues['asset category'] ? [metadataValues['asset category']] : [],
                        "printMedia": metadataValues['documents/sales tools'] ? [metadataValues['documents/sales tools']] : [],
                        "subcategory": metadataValues['flavor'] ? [metadataValues['flavor']] : [],
                        "programCampaign": metadataValues['program / campaign'] ? [metadataValues['program / campaign']] : [],
                        "size": metadataValues['size'] ? [metadataValues['size']] : [],
                        "pOS": metadataValues['pos'] ? [metadataValues['pos']] : [],
                        "brandVariant": metadataValues['timing'] ? [metadataValues['timing']] : [],
                        "categoryProxOneOnly": metadataValues['videos'] ? [metadataValues['videos']] : [],
                        "tradeMarketing": metadataValues['trade marketing'] ? [metadataValues['trade marketing']] : [],
                        "eCommerce": metadataValues['ecommerce'] ? [metadataValues['ecommerce']] : [],
                    }
                }

                formData.append('profile', profileName);
                formData.append('filename', fileName);
                formData.append('metadata', JSON.stringify(metadata));
                formData.append('file', fileData, fileName);

                console.log([...formData])
                promises.push(uploadFile(formData, i)); // Add promise to the array
            }

            const responses = await Promise.all(promises); // Wait for all uploads to finish
            console.log(responses);
        })

        async function uploadFile(formData, number) {
            try {
                const response = await axios.post(
                    'https://api.widencollective.com/v2/uploads',
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: 'Bearer groove/c4b6b46e68ac3b658a16fd1857587174',
                        },
                    }
                );

                // console.log([...formData])
                $('#wait-spinner').modal('hide');
                largeNotification("success", "Done!", "Files were successfully added");

                return response
            } catch (error) {
                $('#wait-spinner').modal('hide');
                largeNotification("error", "Error!", "Something went wrong");
                console.error('Error uploading file:', error);
            }
        }
    }
});