// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// Rails Internals

import $ from 'jquery';
window.jQuery = $;
window.$ = $;

require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("channels");

// require("jquery")
require("popper.js")
require("bootstrap")

import moment from 'moment';
window.moment = moment;

require("daterangepicker")

import owlCarousel from "owl.carousel";

require('jquery-ui');

import Player from '@vimeo/player';
window.Player = Player;

require("packs/main")
require("js-cookie")

import("packs/plugins/css/daterangepicker.min")
import('styles/app.scss');

//import Cookies from 'js.cookie'; <--------- had to disable because line triggering error during Capistrano compilation
import Cookies from 'js-cookie';
window.Cookies = Cookies;

export * from './helpers/helpers'

export * from './UIModules/tabs'
export * from './UIModules/smallNotification'
export * from './UIModules/largeNotification'
export * from './UIModules/copyToClipboard'
export * from './UIModules/dropdownMenuCustom'

export * from './UIModules/sendViaEmailValidation'
export * from './UIModules/emailSubmit'

export * from './UIModules/conversationValidation'
export * from './UIModules/createNewConversation'

export * from './UIModules/addToCollectionValidation'
export * from './UIModules/teamCardSession'

require("packs/drop")
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
